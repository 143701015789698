import React from 'react';
import "./signinOptions.css";
import { useNavigate } from 'react-router-dom';

function SigninOptions() {
  const navigate = useNavigate();
  const handleLogin = ()=>{
    navigate("/login");
  }
  const handleSignup = ()=>{
    navigate("/signup");
  }
  const handleGuest = ()=>{
    navigate("/home");
  }
  return (
    <>
        <div className="signinOption_container">
            {/* <input type='buttom' className='btn btn-primary' name='login_btn' value='Login' />
            <input type='buttom' className='btn btn-success' name='signup_btn' value='Sign Up' />
            <input type='buttom' className='btn btn-light' name='guest_btn' value='Guest' /> */}
            <button type="button" className="w-75 btn btn-primary" onClick={handleLogin}>Login</button>
            <button type="button" className="w-75  btn btn-success" onClick={handleSignup}>Sign Up</button>
            <button type="button" className="w-75  btn btn-secondary" onClick={handleGuest}>Guest</button>
        </div>
    </>
  )
}

export default SigninOptions;