import React from 'react'
import './body.css';
import Flex from './flex/Flex';
import Products from './products/Products';


function Body() {
  return (
    <>
    <div className='bodyMain'>
      <Flex/>
      <Products/>
    </div>
    </>
  )
}

export default Body