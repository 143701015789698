import './App.css';
import MainPage from './components/mainpage/MainPage';
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import Home from './pages/home/Home';
import Body from './components/mainpage/body/Body';
import Header from './components/mainpage/header/Header';
import Footer from './components/mainpage/footer/Footer';
import BottomNav from './components/bottomNav/BottomNav';
import Orders from './pages/orders/Orders';
import Account from './pages/account/Account';
import Wallet from './pages/wallet/Wallet';
import Profile from './pages/profile/Profile';
import LandingPage from './pages/landingPage/LandingPage';
import CheckoutForm from './pages/checkoutForm/CheckoutForm';
import SuccessPage from './pages/successPage/SuccessPage';
import SigninOptions from './pages/signinOptions/SigninOptions';
import { useEffect, useState } from 'react';
import PincodeVerification from './pages/pincodeVerification/PincodeVerification';
import { useCookies } from "react-cookie";
import AllUsers from './pages/admin/users/AllUsers';
import AboutUs from './pages/about/AboutUs';
import Cart from './pages/cart/Cart';
import OrderDetails from './pages/orderDetails/OrderDetails';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {useSelector} from "react-redux";
import Slide from '@mui/material/Slide';

function App() {
  const [showBottomNav, setShowBottomNav] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showAdminBottomNav, setShowAdminBottomNav] = useState(false);
  const [showAdminHeader, setShowAdminHeader] = useState(false);
  const [snackbar, setSnackbar] = useState({"status":false,"msg":"",Transition: Slide});
  const errorObj  = useSelector(state=>state.error);
  const location = useLocation();
  const currentUrl = window.location.href;
  const splitUrl = currentUrl.split('/');
  const lastVal = splitUrl[splitUrl.length - 1];
  const adminVal = splitUrl[splitUrl.length - 1];
  
  const handleClose = ()=>{
    setSnackbar({"status":false,"msg":""})
  }

  useEffect(() => {
    console.log("location", location.pathname)
    if (lastVal !== "" && ['login', 'signup', 'signinoptions'].indexOf(lastVal) === -1 && adminVal.toString() !== "admin") {
      setShowHeader(true);
      setShowBottomNav(true);
    } else {
      setShowHeader(false);
      setShowBottomNav(false);
    }
  }, [location]);

  useEffect(() => {
    console.log("error Obj", errorObj);
    if (errorObj.data.status === true) {
      setSnackbar({ "status": true, "msg": errorObj.data.message, "type": errorObj.data.type })
    }
  }, [errorObj])

  const [cookies] = useCookies(['token']);

  return (
    <>
      <img className='bg-image' width={100} height={100} src='https://static.vecteezy.com/system/resources/previews/008/622/667/large_2x/recycling-activity-background-free-vector.jpg' />
      <div className="App col-12 col-md-4">
        <Snackbar
          open={snackbar.status}
          autoHideDuration={3000}
          onClose={handleClose}
          TransitionComponent={snackbar.Transition}
        >
          <Alert
            severity={snackbar.type}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.msg}
          </Alert>
        </Snackbar>
        {showHeader && <Header adminVal />}
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route exact path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/home' element={<Home />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/order-details/:order_id' element={<OrderDetails />} />
          <Route path='/account' element={<Account />} />
          <Route path='/wallet' element={<Wallet />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/pincodeVerification' element={<PincodeVerification />} />
          <Route path='/checkoutForm' element={<CheckoutForm />} />
          <Route path='/successPage' element={<SuccessPage />} />
          <Route path='/signinoptions' element={<SigninOptions />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/admin/users' element={<AllUsers />} />
          {/* </Route> */}
        </Routes>
        {/* <Footer/> */}
        {showBottomNav && <BottomNav adminVal />}

      </div>
    </>
  );
}

export default App;
