import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import data from "../../../config.json";


const signupUser = createAsyncThunk(
    'users/signup',
    async (postData, thunkAPI) => {
        console.log('[signup user] postData', postData!== undefined ? postData : '');
        let form_data = new FormData();

        for ( let key in postData ) {

            form_data.append(key, postData[key]);
        }
        try {
            const url = data?.api?.signup_post;
            if(url){
                const response = await axios.post(url, form_data);
                console.log('[signup user] response',response );
                if(response.status === 200 && response.data.status === true ){
                    return thunkAPI.fulfillWithValue(response.data);
                }else{
                    throw thunkAPI.rejectWithValue(response.data.message);
                }
            }else{
                throw thunkAPI.rejectWithValue('No API URL found');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
  )
  export default signupUser;