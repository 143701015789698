import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import data from "../../../config.json";

const logoutUser = createAsyncThunk(
    'users/logout',
    async (postData, thunkAPI) => {
        console.log('[fetchUserById] postData', postData !== undefined ? postData : '');
        try {
            const url = data?.api?.logout_get;
            const token = typeof postData?.token !== "undefined" && "Bearer " + postData.token
            const config = {
                headers: { 
                    Authorization: token,
                    "Access-Control-Allow-Origin":  'https://recyclebee.ca/api/user/logout',
                    "Access-Control-Allow-Headers": "Content-Type, Authorization",
                }
            };
            console.log(url,config);
            if (url) {
                const response = await axios.get(url, config)
                console.log('[fetchUserById] response', response);
                if(response.status === 200 && response.data.status === true ){
                    return thunkAPI.fulfillWithValue(response.data.data);
                }else{
                    throw "something went wrong"
                }
            } else{
                throw "url not found"
            }
        } catch (error) {
            console.log("error",error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export default logoutUser;