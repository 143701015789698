import React from 'react';
import "./orderDetailCard.css";
import dustbin from "../../../public/images/dustbin.png";
import Chip from '@mui/material/Chip';
import cash from '../../../public/images/dollars.png';
import deleteImg from "../../../public/images/multiply.png";
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

function OrderDetailCard(props) {
  const productName = props?.order_detail?.product_details_array?.product_name;
  console.log("props?.order?.reward_redeem_mode", props);

  const dispatch = useDispatch();
  const handleClick = () => {
    props.openModal();
    props.setCurrentData({
      order_id: props?.order_detail?.order_id,
      product_id: props?.order_detail?.id,
      qty: props?.order_detail?.product_quantity
    })
  }


  return (
    <div>

      <div className={`orderCard ${props?.order_detail?.product_status === 'cancelled' && 'cancelledDiv'} px-3 py-2`}>
        <div className='d-flex flex-row'>
          <img width={50} height={50} src={props?.order_detail?.product_details_array?.product_image} />
          <div className='orderContentDiv'>
            <div className='orderDate orderDetailName'>{productName.length > 15 ? productName.toUpperCase().replace(productName.toUpperCase().slice(15, productName.length), '...') : (productName).toUpperCase()}</div>
            <div className='pickUpDate'>{new Date(props?.order_detail?.created_at).toUTCString().slice(0, -7)}</div>
            <div className='rewardMode'>Total Points : <strong>{(((props?.order_detail?.or_product_reward_points * props?.order_detail?.product_quantity) * props?.order_detail?.or_product_reward_cash_conversion_percentage / 100) / 100).toFixed(2)}</strong></div>
          </div>
        </div>
        <div className='orderPrice'>
          <div className='orderFinalAmt'>{props?.order_detail?.product_details_array?.product_recycling_operation_fee}$</div>
          <div style={{ fontSize: "13px", color: "#20c997" }}>Qty:{props?.order_detail?.product_quantity}</div>
        </div>
        {props?.order_detail?.product_status !== 'cancelled' &&
          <Tooltip title="cancel request">
            <IconButton>
              <div onClick={handleClick}>
                <img src={deleteImg} style={{ cursor: "pointer" }} width="25px" height="25px" />
              </div>
            </IconButton>
          </Tooltip>
        }
        {(props?.order_detail?.product_status == "requested") && <Chip label="Requested" color="info" />}
        {(props?.order_detail?.product_status == "approved") && <Chip label="Approved" color="secondary" />}
        {(props?.order_detail?.product_status == "processing") && <Chip label="Processing" color="warning" />}
        {(props?.order_detail?.product_status == "completed") && <Chip label="Completed" color="success" />}
        {(props?.order_detail?.product_status == "rejected") && <Chip label="Rejected" color="error" />}
        {(props?.order_detail?.product_status == "cancelled") && <Chip label="Cancelled" color="error" />}
        {/* {props?.order_detail?.reward_redeem_mode === "cash" && <img width={30} height={30} src={cash} />} */}
        {/* <ArrowForwardIosIcon className='orderCardArrow' onClick={handleClick} sx={{color:"green",marginRight:"10px"}}/> */}
      </div>
    </div>
  )
}

export default OrderDetailCard