import React from 'react'
import "./landingPage.css";
import Flex from '../../components/mainpage/body/flex/Flex';
import  { useNavigate  } from 'react-router-dom'

function LandingPage() {
const navigate = useNavigate();
const handleClick = ()=>{
  console.log(123);
  navigate('/signinoptions');
}

  return (
    <div>
        <Flex />
        <input type='button' className='form-control btn btn-primary landPageBtn' onClick={handleClick} value="Let's Start"/>
    </div>
  )
}

export default LandingPage