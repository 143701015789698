import React,{useState} from 'react';
import "./checkoutForm.css";
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { useSelector, useDispatch} from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import orderCheckout from "../../store/thunk/checkoutRequest/CheckoutRequest";
import { deleteCart } from '../../store/thunk/cart/cartSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


function CheckoutForm() {
  const [cookies] = useCookies(['token']);
  const postal_code = JSON.parse(localStorage.getItem('postal_code'));
  const street_id = JSON.parse(localStorage.getItem('street_id'));
  const dustbin_check = JSON.parse(localStorage.getItem('dustbin_check'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector(state=>state.cart);
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const [snackbar, setSnackbar] = useState({ "status": false, "msg": "", Transition: Slide });
  const [showLoading, setShowLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const handleClickSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    setShowLoading(true);
    const form = document.querySelector('.checkoutForm');
    const formData = new FormData(form);
    console.log("form data", formData);
    let name, email, mobile, address, postal_code, country,city, postal_id, dustbin, reward_redeem_mode;
    name = formData.get('name');
    email = formData.get('email');
    mobile = formData.get('mobile');
    address = formData.get('address');
    postal_code = formData.get('pincode');
    country = "Canada";
    city = "Saint John";
    reward_redeem_mode = formData.get('payment_mode');
    postal_id = street_id ? street_id : '';
    // dustbin = dustbin_check ? dustbin_check : '';
    let finalCart = [];
    cartData.data.map(dt=>{
      if(dt.id !== 0){
        finalCart = [...finalCart, {id:dt.id,qty: dt.qty} ];
      }
    }) 
    const obj = {
      mobile, 
      address:{
        address,
        postal_code,
        country,
        city
      },
      reward_redeem_mode,
      // products: dustbin_check ? [...finalCart,{id:'6',qty: 1}]: finalCart,
      products: finalCart,
      token: cookies?.token
    };

    if(obj.mobile.length < 10 || obj.mobile.length > 10){
      setSnackbar({ "status": true, "msg": "Please provide valid mobile number", "type": "error" })
      setShowLoading(false);
      setDisabled(false);
    }else if(obj.address.length > 50){
      setSnackbar({ "status": true, "msg": "City, State not required & Address must not be more then 50 characters", "type": "error" })
      setShowLoading(false);
      setDisabled(false);
    }
    else if(obj.address === ""){
      setSnackbar({ "status": true, "msg": "Please fill the address", "type": "error" })
      setShowLoading(false);
      setDisabled(false);
    }
    else if(obj.location === ""){
      setSnackbar({ "status": true, "msg": "Location is not defined, Please go back to pincode page and give location permission", "type": "error" })
      setShowLoading(false);
      setDisabled(false);
    }
    else{
      dispatch(orderCheckout(obj)).then((res)=>{
        if (typeof res?.type !== "undefined" && res.type.toString() === "users/checkout/fulfilled") {
          setShowLoading(false);
          setDisabled(false);
        }else if(typeof res?.type !== "undefined" && res.type.toString() === "users/checkout/rejected"){
          setShowLoading(false);
          setDisabled(false);
          setSnackbar({ "status": true, "msg": res?.payload?.response?.data?.message, "type": "error" })
        }
        if(typeof res?.payload?.data?.payment_required !== "undefined" && res?.payload?.data?.payment_required === false){
          cartData.data.map(dt=>{
            if(dt.id !== 0){
              dispatch(deleteCart(dt.id));
            }
          })
          navigate('/successPage');
        }else if(res?.payload?.data?.payment_required === true){
          window.location.href = res?.payload?.data?.url;
        }
      })
    }
  }
  const handleClickBack = () => {
    navigate('/cart');
  }
  const handleClose = () => {
    setSnackbar({ "status": false, "msg": "" })
  }

  if (typeof cookies.token === "undefined" || !cookies.token || cookies.token.length < 40) {
    navigate('/login');
  } else {
    return (
      <div className='checkoutDiv'>
        <Snackbar
                open={snackbar.status}
                autoHideDuration={3000}
                onClose={handleClose}
                TransitionComponent={snackbar.Transition}
            >
                <Alert
                    severity={snackbar.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.msg}
                </Alert>
            </Snackbar>
        <div className='checkoutTitle'>CHECKOUT FORM</div>
        {showLoading &&
          <Box className="checkoutLoader" sx={{ display: 'flex', position:"fixed", padding:"2rem", backgroundColor:"#fff", top:"22rem", zIndex:"100", height:"10% !important",left:"40%" }}>
            <CircularProgress />
          </Box>
        }
        <div className='checkoutBar'>
          <form className="checkoutForm">
            <div>
              <label>Name</label>
              <input type="text" className="form-control" name="name" value={userData?.name} placeholder="full name" required />
            </div>
            <div>
              <label>Email</label>
              <input type="email" className="form-control disabledData" name="email" placeholder="e-mail" value={userData?.email}  disabled/>
            </div>
            <div>
              <label>Mobile</label>
              <input type="number" className="form-control" name="mobile" placeholder="mobile" value={userData?.phone} required />
            </div>
            <div>
              <label>Payment Mode</label>
              <select type="text" className="form-control" name="payment_mode" required >
                <option value="cash" defaultChecked>Cash</option>
                <option value="point" >Reward Points</option>
              </select>
            </div>
            <div>
              <label>Address</label>
              <input type="text" className="form-control" name="address" placeholder="address" required />
            </div>
            <div>
              <label>Postal Code</label>
              <input type="text" className="form-control disabledData" name="pincode" placeholder="pincode"/>
            </div>
            <div>
              <label>City</label>
              <input type="text" className="form-control disabledData" name="city" placeholder="city" value="Saint John" disabled />
            </div>
            <div>
              <label>Country</label>
              <input type="text" className="form-control disabledData" name="country" placeholder="country" value="Canada" disabled />
            </div>
          </form>
        </div>
        <div className='checkoutBtnDiv' >
          {true && <button type='button' onClick={handleClickBack} className='btn btn-default checkout-btn'>Back</button>}
          <button type='button' onClick={handleClickSubmit} className='btn btn-success checkout-btn' disabled={isDisabled}>Place Order</button>
        </div>
      </div>
    )
  }
}

export default CheckoutForm