import React, { useEffect, useState } from 'react';
import './login.css';
import loginUser from "../../store/thunk/login/loginRequest";
import { getUser } from "../../store/thunk/login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import data from "../../config.json";
import login from "../../public/images/login.jpg";
import { Link } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import ReplyIcon from '@mui/icons-material/Reply';
import getUserDetails from "../../store/thunk/user/userRequest"
import { setMessage } from "../../store/thunk/error/errorMessage"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [snackbar, setSnackbar] = useState({ "status": false, "msg": "", Transition: Slide });
  const storeUser = useSelector(getUser)
  const errorObj = useSelector(state => state.error)
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const dispatch = useDispatch();

  const handleClose = () => {
    setSnackbar({ "status": false, "msg": "" })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setShowLoader(true);
    const userData = {
      "email": email,
      "password": password,
    }
    console.log('userData', userData);
    if (userData.email && userData.password) {
      dispatch(loginUser(userData))
        .then(res => {
          console.log("res", res);
          if(typeof res?.type !== "undefined" && res.type.toString() === "users/login/fulfilled"){
            setShowLoader(false);
          } else if(res.error) {
            let message = res.payload.response.request.response;
            const parsedData = JSON.parse(message);
            console.log("message", parsedData);
            setSnackbar({ "status": true, "msg": parsedData.message, "type": "error" })
          } else if (res && res.payload.token !== "") {
            setCookie("token", res.payload.token);
            setSnackbar({ "status": true, "msg": "Successfully Login", "type": "success" })
          }
        })
    } else {
      setSnackbar({ "status": true, "msg": "Please input valid data", "type": "info" })
    }
  }
  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    navigate("/signup");
  }
  useEffect(() => {
    console.log("storeUser", storeUser);
    if (storeUser && typeof storeUser.data.token !== "undefined" && storeUser.data.token !== "") {
      dispatch(getUserDetails({
        token: storeUser.data.token
      })).then((res) => {
        console.log("res", res);
        if (typeof res?.type !== "undefined" && res.type.toString() === "users/getuser/fulfilled") {
          setCookie("token", storeUser.data.token);
          const { name, email, phone } = storeUser?.data?.user;
          localStorage.setItem("user_data", JSON.stringify({ name, email, phone }));
        } else if (res?.payload?.response?.status === 401) {
          console.log("login page cookie removed");
          removeCookie('token', '/');
          window.location.reload();
        }
      })
      if (typeof props?.flag !== "undefined" && props.flag === true) {
        navigate("/checkoutForm");
      } else {
        navigate("/home");
      }
      setSnackbar({ "status": true, "msg": "Successfully Login", "type": "success" })
    }
    else if (typeof cookies.token !== "undefined" && cookies.token) {
      console.log("cookies.token", cookies.token, cookies.token.length);
      dispatch(getUserDetails({
        token: cookies?.token
      })).then((res) => {
        if (typeof res?.type !== "undefined" && res.type.toString() === "users/getuser/fulfilled") {
          navigate("/home");
        } else if (typeof res?.type !== "undefined" && res.type.toString() === "users/getuser/rejected") {
          navigate("/login");
        }
      })
    }
  }, [storeUser])

  useEffect(() => {
    setShowLoader(false);
  }, [])
  return (
    <>
      <div className='LoginDiv'>
        <Snackbar
          open={snackbar.status}
          autoHideDuration={3000}
          onClose={handleClose}
          TransitionComponent={snackbar.Transition}
        >
          <Alert
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.msg}
          </Alert>
        </Snackbar>
        <img width={100} height={100} src={login} />
        <h1>Login</h1>
        {showLoader && <Box className="loginLoader" sx={{ display: 'flex', position:"fixed", padding:"2rem", backgroundColor:"#fff", top:"22rem", zIndex:"100", height:"10% !important" }}>
          <CircularProgress />
        </Box>}
        <form className="loginForm">
          <input type="email" className="form-control mb-3" name="email" onChange={(e) => setEmail(e.target.value)} placeholder="user name" />
          <input type="password" className="form-control mb-3" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="password" />
          <input type="button" className="btn btn-primary Login-btn" onClick={handleSubmit} value="Login" />
          {(typeof props?.flag === "undefined" || props.flag === false) && <input type="button" className="btn register-btn" onClick={handleRegisterSubmit} value="Register" />}
          <Link style={{ textAlign: "start" }} to="/forgot-password">forgot password</Link>
        </form>
        <Link to="/home"><ReplyIcon />Go to homepage</Link>
      </div>
    </>
  )
}

export default Login