import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import data from "../../../config.json";


const fetchAllPincodes = createAsyncThunk(
    'users/pincode',
    async (postData, thunkAPI) => {
        console.log('[fetchAllPincodes] postData', postData !== undefined ? postData : '');
        try {
            const url = data?.api?.product_get;
            console.log("url",url);
            const response = await axios.post(url,postData)
            console.log('[fetchAllPincodes] response', response,typeof response.data.fetch_postal_codes );
            if(response.status === 200 && response.data.status === true && typeof response.data.fetch_postal_codes !== undefined){
              return thunkAPI.fulfillWithValue(response.data.data);
            }else{
              return thunkAPI.rejectWithValue(response.data.message);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
  )
  export default fetchAllPincodes;