import { createSlice } from '@reduxjs/toolkit';
import getUserDetails from './userRequest';



const initialState = {
  data:{
    name:'test',
    email:'test@xyz.com',
    Phone: '9998887774',
    type: "retail"
  },
    loading: false,
    isError: false
};
const updateUserData = (state, currentData)=>{
  state.data = {...currentData};
  return state
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          .addCase(getUserDetails.pending, (state, action) => {
            state.isError = false
            state.loading = true
          })
          .addCase(getUserDetails.fulfilled, (state, action) => {
            console.log('getUserDetails.fulfilled',action);
            updateUserData(state,action.payload);
            state.isError = false
            state.loading = false
          })
          .addCase(getUserDetails.rejected, (state, action) => {
            console.log('getUserDetails.rejected',action);
            state.isError = true
            state.loading = false
            state.errorMessage = action.payload.message
          })
      },
});

export const getUser = (state)=>{
  return state.user
}

export default userSlice.reducer;
