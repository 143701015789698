import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';


const fetchCartById = createAsyncThunk(
    'users/id',
    async (cartId, thunkAPI) => {
        console.log('[fetchUserById] cartId', cartId !== undefined ? cartId : '');
        try {
            // const {id} = userId
            const response = await axios.get(`https://dummyjson.com/products/` + cartId)
            console.log('[fetchUserById] response', response );
          return thunkAPI.fulfillWithValue(response.data);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
  )
  export default fetchCartById;