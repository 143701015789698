import { createSlice } from '@reduxjs/toolkit';
import fetchAllPincodes from './pincodeRequest';



const initialState = {
    data: [
        {
            "id": 0,
            "street": "",
            "city": "",
            "postal_code": "",
            "created_at": null,
            "updated_at": null
        }
    ],
    loading: false,
    isError: false
};
const updatePincodeList = (state, currentData) => {
    state.data = [ ...currentData?.fetch_postal_codes ];
    return state
}

const productSlice = createSlice({
    name: 'Pincode',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllPincodes.pending, (state, action) => {
                state.isError = false
                state.loading = true
            })
            .addCase(fetchAllPincodes.fulfilled, (state, action) => {
                console.log('fetchAllPincodes.fulfilled', action);
                updatePincodeList(state, action.payload);
                state.isError = false
                state.loading = false
            })
            .addCase(fetchAllPincodes.rejected, (state, action) => {
                console.log('fetchAllPincodes.rejected', action);
                state.isError = true
                state.loading = false
                state.errorMessage = action.payload.message
            })
    },
});

export const getProducts = (state) => {
    return state.product
}
export default productSlice.reducer;
