import React, { useState } from 'react';
import './signup.css';
import { Link } from 'react-router-dom';
import signupUser from "../../store/thunk/signupUser/signupUserRequest";
import login from "../../public/images/login.jpg";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';

function Signup(props) {
  const [snackbar, setSnackbar] = useState({ "status": false, "msg": "", Transition: Slide });
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['token']);
  const navigate = useNavigate()
  const handleClose = () => {
    setSnackbar({ "status": false, "msg": "" })
  }

  const handleSubmit = (e) => {
    console.log("handle submit triggered")
    e.preventDefault();
    const form = document.querySelector('.signupForm');
    const formData = new FormData(form);
    console.log("formData", formData);
    let name, email, type, password,mobile, password_confirmation;
    name = formData.get('name');
    email = formData.get('email');
    type = formData.get('type');
    mobile = formData.get('mobile');
    password = formData.get('password');
    password_confirmation = formData.get('re-password');
    const obj = { name, email, type,mobile, password, password_confirmation };
    console.log("obj", obj);
    if (obj.password.toString() !== obj.password_confirmation.toString()) {
      setSnackbar({ "status": true, "msg": "Password does not matched", "type": "info" })
    } else {
      if(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(obj.password) === false){
        setSnackbar({ "status": true, "msg": "password must contains atleast one number, one alphabet and one special character, must be between 8-20 characters", "type": "error" })
      }else if (obj.name && obj.email && obj.type && obj.password && obj.password_confirmation) {
        dispatch(signupUser(obj))
          .then(res => {
            console.log("res", res);
            if(res.error){
              let message = res.payload.response.request.response;
              const parsedData = JSON.parse(message);
              console.log("message",parsedData);
              setSnackbar({ "status": true, "msg": parsedData.message, "type": "error" })
            }else if (res && res.payload.data.token !== "") {
              setCookie("token", res.payload.data.token);
              setSnackbar({ "status": true, "msg": "Successfully Login", "type": "success" })
              if (typeof props?.flag !== "undefined" && props.flag === true) {
                navigate('/checkoutForm');
              } else {
                navigate('/home');
              }
            }
          })
      } else {
        setSnackbar({ "status": true, "msg": "All fields are required", "type": "error" })
      }
    }
  }
  return (
    <>
      <Snackbar
        open={snackbar.status}
        autoHideDuration={3000}
        onClose={handleClose}
        // message={snackbar.msg}
        TransitionComponent={snackbar.Transition}
      >
        <Alert
          severity={snackbar.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.msg}
        </Alert>
      </Snackbar>
      <div className='signupDiv'>
        <div className='topBar'>
          <img className='signupImage' src={login} />
          <h1>Create Account</h1>
          {(typeof props?.flag === "undefined" || props.flag === false) && <Link to="/login" ><div>Already account, click here</div></Link>}
          <Link style={{marginTop:"10px"}} to="/home"><ReplyIcon/>Go to homepage</Link>
        </div>
        <div className='bottomBar'>
          <form className="signupForm" >
            <label>Name</label>
            <input type="text" className="form-control" name="name" placeholder="Full Name" required />
            <label>Email</label>
            <input type="email" className="form-control" name="email" placeholder="E-mail" required />
            <label>Mobile No</label>
            <input type="number" className="form-control" name="mobile" placeholder="mobile no" required />
            <label>User Type</label>
            <select className="form-control" name="type" defaultValue="" required>
              <option value="retail" defaultChecked>Single User</option>
              <option value="corporte">Corporate User</option>
            </select>
            <label>Password</label>
            <input type="password" className="form-control" name="password" placeholder="password" required />
            <label>Confirm Password</label>
            <input type="password" className="form-control" name="re-password" placeholder="password" required />
          </form>
        </div>
        <input type="button" className="btn btn-primary signup-btn" onClick={handleSubmit} value="Sign Up" />
      </div>
    </>
  )
}

export default Signup