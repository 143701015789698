import React,{useState} from 'react';
import Login from '../../pages/login/Login';
import "./LoginModal.css";
import RegisterModal from "../registerModal/RegisterModal";

function LoginModal() {
const [showComponent, setShowComponent] = useState(false);
const handleClick = (e)=>{
  e.preventDefault();
  setShowComponent(true);
}
  return (
    <div className='loginModalDiv'>
        {showComponent && <RegisterModal />}
        {!showComponent && <Login flag={true}/>}
        {!showComponent && <button type='button' className='btn register' onClick={handleClick}>Register</button>}
    </div>
  )
}

export default LoginModal