import React from 'react';
import "./successPage.css";
import { useNavigate,useParams } from 'react-router-dom';

function SuccessPage() {
    const navigate = useNavigate();
    const param = useParams();

    const handleViewOrder = ()=>{
        if(typeof param?.order_id !== "undefined" && param.order_id){
            navigate(`/order-details/${param?.order_id}`);
        }else{
            navigate(`/orders`);
        }
    }

  return (
    <div className='successPageDiv'>
        <img src='https://media.tenor.com/f2lbsAWTYlYAAAAi/check-symbols.gif' width={100} height={100}/>
        <div className='successTitle'>Your Order Has been Placed</div>
        {/* <div className='successSubMsg'>An email has been send back to your email, test@test.com</div>
        <div className='orderDetailDiv'>
            <div className='orderDetails'>Order Details</div>
            <div className='orderRow'>
                <div>Total Amount</div>
                <div>5$</div>
            </div>
            <div className='orderRow'>
                <div>Order Number</div>
                <div>10552465245</div>
            </div>
            <div className='orderRow'>
                <div>Reward Points</div>
                <div>10</div>
            </div>
        </div> */}
        <button type='button' onClick={handleViewOrder} className='w-100 mt-4 btn btn-success'>View Your Order</button>
        <div className='thanksDiv'>THANK YOU</div>
    </div>
  )
}

export default SuccessPage