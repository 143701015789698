import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import data from "../../../config.json";


const forgotPassword = createAsyncThunk(
    'users/forgotpassword',
    async (postData, thunkAPI) => {
        console.log('[forgotPassword] postData', postData !== undefined ? postData : '');
        try {
            const url = data?.api?.forgot_password_post;
            if (url) {
                const response = await axios.post(url,postData)
                console.log('[forgotPassword] response', response);
                if(response.status === 200 && response.data.status === true ){
                    return thunkAPI.fulfillWithValue(response.data.data);
                }else{
                    throw "something went wrong"
                }
            } else{
                throw "url not found"
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
)
export default forgotPassword;