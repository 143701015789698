import { configureStore  } from '@reduxjs/toolkit';
import loginReducer from './thunk/login/loginSlice.js';
import userReducer from './thunk/user/userSlice.js';
import cartReducer from './thunk/cart/cartSlice.js';
import productReducer from './thunk/product/productSlice.js';
import pincodeReducer from './thunk/pincode/pincodeSlice.js';
import orderReducer from './thunk/order/orderSlice.js';
import walletReducer from './thunk/wallet/walletSlice.js';
import accountReducer from './thunk/account/accountSlice.js';
import orderDetailReducer from './thunk/orderDetails/orderDetailSlice.js';
import errorMessageReducer from './thunk/error/errorMessage.js';

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false
// })
export default configureStore({
  reducer: {
    login:loginReducer,
    error:errorMessageReducer,
    user:userReducer,
    cart:cartReducer,
    product:productReducer,
    pincode:pincodeReducer,
    order:orderReducer,
    orderDetail:orderDetailReducer,
    wallet:walletReducer,
    account:accountReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})