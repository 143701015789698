import React, { useEffect, useState } from 'react';
import './cart.css';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../components/card/Card';
import { useNavigate } from 'react-router-dom';
// import bottle from "../../public/images/wine-bottles.png";
// import can from "../../public/images/can.png";
import dustbin from "../../public/images/dustbin.png";
// import fridge from "../../public/images/fridge.png";
import { useCookies } from "react-cookie";
import { addCart,deleteCart } from '../../store/thunk/cart/cartSlice';
import { setMessage } from '../../store/thunk/error/errorMessage';
import LoginModal from '../../components/loginModal/LoginModal';




function Cart() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const cartItems = useSelector(state => state.cart);
  const [initialVal, setInitialVal] = useState([...cartItems?.data]);
  const [remove, setRemove] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [dustbinCheck, setDustbinCheck] = useState(true);
  const [add, setAdd] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies] = useCookies(['token']);
  
  // const [cookies] = useCookies(['token']);

  const handleDustbinBtnClick = () => {
    // setDustbinCheck(!dustbinCheck);
  //   setAdd(!add);
  //   setRemove(!remove);
  //   if(remove){
  //       setTotalAmount(totalAmount - 5)
  //   }else{
  //       setTotalAmount(totalAmount + 5)
  //   }
  }
  useEffect(() => {
    setInitialVal([...cartItems.data])
  }, [cartItems])
  const handleClick = () => {
    // localStorage.setItem('dustbin_check', dustbinCheck);
    // if(remove){
        // dispatch(
        //     addCart(
        //         {
        //             id : 6,
        //             qty : 1
        //         }
        //     )
        // )
    // }
    if(totalQty < 50){
      dispatch(setMessage(
        {
          status:true,
          message:"Minimum 50 Qty required",
          type:"error"
        }
      ))
    }else{
      if (typeof cookies?.token !== "undefined" && cookies.token) {
        navigate('/checkoutForm');
      } else {
          setShowLoginModal(true);
      }
    }
    // setTotalQty(0);
  }
  // useEffect(() => {
  //   if (typeof inputVal != undefined) {
  //     const indexData = initialVal?.findIndex(index => index.id == inputVal.id);
  //     if (indexData !== -1) {
  //       setInitialVal(initialVal.splice(indexData, 0))
  //     }
  //   }
  // }, [inputVal])
  useEffect(() => {
    setTotalQty(0)
    let total = 0;
    let totalQuantities = 0;
    initialVal.map(dt=>{
        if(dt.id !== 0){
          totalQuantities = totalQuantities + dt?.qty;
          total = (total + (Number(dt.product_recycling_operation_fee) * Number(dt.qty)));
        }
      })
      // if(remove === true){
        //     total = total + 5;
        // }
    setTotalQty(totalQuantities);
    setTotalAmount(total);
  }, [initialVal])

  // if (typeof cookies.token === "undefined" || !cookies.token || cookies.token.length < 40) {
  //   navigate('/login');
  // } else {
  return (
    <>
    {showLoginModal && 
      <div className='loginMainDiv'> 
         <LoginModal /> 
      </div>
    }
    {!showLoginModal && <div>
      <div className='orderTitle'>PRODUCT LIST</div>
      <div className={`orderCardDiv ${initialVal.length > 1 ? "scroll" : "" }`}>
        {initialVal.map((item) => {
          if (item.id > 0 && item.qty > 0 && initialVal.length > 1) {
            return <Card key={item.id} fullData={item} />
          } else {
            return false;
          }
        })}
        {
          initialVal.length <= 1 &&
          <div className='noOrderImg'>
            <img width="100%" height="100%" src='https://static.vecteezy.com/system/resources/previews/014/814/311/large_2x/no-order-a-flat-rounded-icon-is-up-for-premium-use-vector.jpg' />;
          </div>
        }
        {/* {
          initialVal.length > 1 && <div className='dustbinContainer'>
            <img className='dustbin' src={dustbin} alt='dustbin' width={50} height={50} />
            <div className='dustbinTextDiv'>
              <div>PRICE : 5$</div>
              <div className='dustSubText'>Dustbin is mendatory for any garbage collection order.</div>
            </div>
            {remove && <button type='button' onClick={handleDustbinBtnClick} className='btn btn-secondary '>Remove</button>}
            {add && <button type='button' onClick={handleDustbinBtnClick} className='btn btn-danger '>Add</button>}
          </div>
        } */}
      </div>
      {
        initialVal.length > 1 && <div className='orderBottomDiv'>
          <div style={{textAlign: "center",fontSize: "1.3rem"}}>Total : <span style={{color:"#007bff", fontSize:"20px"}}>{`${totalAmount}$`}</span></div>
          {initialVal.length > 1 && <button type='button' onClick={handleClick} className='btn btn-success order-btn'>Checkout</button>}
        </div>
      }
      </div>}
    </>
  )
}
// }

export default Cart