import React, { useEffect, useRef, useState } from 'react'
import './card.css';
import { useDispatch, useSelector } from 'react-redux';
import { addCart, updateCart, deleteCart } from '../../store/thunk/cart/cartSlice';
import QuantityInput from '../QuantityInput/QuantityInput';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import plus from "../../public/images/add.png"
import minus from "../../public/images/minus.png";

function Card(props) {
  const ref = useRef();
  const qtyRef = useRef();
  const dispatch = useDispatch();
  const [inputVal, setInputVal] = useState(props.fullData.qty != "undefined" && props.fullData.qty ? props.fullData.qty : 0);
  const [inputValChange, setInputValChange] = useState(false);
  const cart = useSelector(state => state.cart);

  /****** CRUD operation for cart in redux store*******/
  useEffect(() => {
    setInputVal(props.fullData.qty != "undefined" && props.fullData.qty ? props.fullData.qty : 0)
  }, [props?.fullData?.qty])
  useEffect(() => {
    const newObj = {
      id: ref.current.getAttribute("id"),
      product_name: ref.current.getAttribute("name"),
      qty: inputVal,
      product_image: ref.current.getAttribute("image"),
      price: ref.current.getAttribute("price"),
      unit: ref.current.getAttribute("unit"),
      product_description: ref.current.getAttribute("desc"),
    }
    // console.log("newObj", newObj);
    if (newObj.qty > 0) {
      if (Number(cart.data.findIndex(dt => dt.product_name.toString() === newObj?.product_name?.toString())) === -1) {
        // // console.log("add cart triggered");
        dispatch(
          addCart(
            {
              id: newObj.id,
              product_image: newObj.product_image,
              product_name: newObj.product_name,
              qty: newObj.qty,
              product_recycling_operation_fee: newObj.price,
              unit: newObj.unit,
              product_description: newObj.product_description
            }
          )
        )   //add to store.cart
      } else {
        // console.log("update cart triggered");
        const { id, product_name, qty, product_image, product_description, price, unit } = newObj
        dispatch(
          updateCart(
            {
              id,
              product_name,
              qty,
              product_recycling_operation_fee: price,
              unit,
              product_image,
              product_description
            }
          )
        );   //update to store.cart
      }
    } else {
      // const findRow = cart.data.find(dt=>dt.id === newObj.id);
      // const matchQtyWithCart = (findRow != "undefined") ? findRow?.qty : null;

      if (inputValChange) {
        if (Number(newObj?.id) > 0) {
          dispatch(deleteCart(newObj.id))     //delete to store.cart
        }
      }
    }
  }, [inputVal]);

  const handleInput = (e) => {
    e.preventDefault();
    console.log("input changed");
    !inputValChange && setInputValChange(true);
    // console.log("set inout val triggered");
    if (e.target.value != "" || !e.target.value.match(/^[1-9]\d*\.?\d*$/)) {
      setInputVal(null)
    }
    const numVal = Number(e.target.value)
    if (typeof numVal === 'number' && numVal > 0 && numVal < 2000) {
      setInputVal(numVal);
    }
  }

  const handleClick = (e) => {
    // e.preventDefault();
    !inputValChange && setInputValChange(true);
    console.log("set inout val triggered");
    if (e.currentTarget.className === "plus") {
      setInputVal(inputVal + 1);
    } else if (e.currentTarget.className === "minus") {
      if (inputVal > 0) {
        setInputVal(inputVal - 1);
      }
    }
  }

  const handleTextShow = (e) => {
    e.preventDefault()
    let className = e.target.className;
    if (className.split(" ").length > 1) {
      className = className.split(" ").shift();
    }
    console.log("ref.current.children[1]", ref.current.children[1]);
    if (className === "showmore") {
      ref.current.className = ref.current.className + " cardEvent";
      ref.current.children[1].children[3].className = ref.current.children[1].children[3].className + " showlessEvent"
      ref.current.children[1].children[2].className = ref.current.children[1].children[2].className + " showmoreEvent"
      ref.current.children[2].className = ref.current.children[2].className + " productDescEvent";
    } else if (className === "showless") {
      ref.current.className = ref.current.className.split(" ").shift();
      ref.current.children[2].className = ref.current.children[2].className.split(" ").shift();
      ref.current.children[1].children[2].className = ref.current.children[1].children[2].className.split(" ").shift();
      ref.current.children[1].children[3].className = ref.current.children[1].children[3].className.split(" ").shift();
    }
  }

  return (
    <div ref={ref} id={props.fullData.id} price={props.fullData.product_recycling_operation_fee} unit={props.fullData.unit} name={props.fullData.product_name} image={props.fullData.product_image} desc={props.fullData.product_description} className='card' inputVal={inputVal}>
      <img width={100} height={100} src={props.fullData.product_image && props.fullData.product_image} />
      <div className='contentText'>
        <p style={{ fontWeight: '700' }}>{props.fullData.product_name.length > 15 ? props.fullData.product_name.toUpperCase().replace(props.fullData.product_name.toUpperCase().slice(15, props.fullData.product_name.length), '...') : (props.fullData.product_name).toUpperCase()}</p>
        <p>{props.fullData.product_recycling_operation_fee}$/{props.fullData.unit}</p>
        <a className='showmore' onClick={handleTextShow} href='#'>SHOW MORE</a>
        <a className='showless' onClick={handleTextShow} href='#'>SHOW LESS</a>
      </div>
      <p className='productDesc'>{props.fullData.product_description !== '' ? props.fullData.product_description : "No Description"}</p>
      {/* <QuantityInput defaultValue="0"/> */}
      {(props.fullData.product_status !== "comingsoon") ? 
      <div>
        <div className='numberInput'>
          {/* <button className='minus' name='minus' onClick={(e)=>handleClick(e)}><span style={{position: "relative",bottom: "4px"}}>- </span></button> */}
          <div className='minus' name='minus' onClick={(e)=>handleClick(e)}><img width="20" height="20" src={minus} /></div>
          <div style={{ width: '100%' }}>
            <input value={inputVal} name='qty' ref={qtyRef} onChange={handleInput} />
          </div>
          <div className='plus' name='plus' onClick={(e)=>handleClick(e)}><img width="20" height="20" src={plus} /></div>
          {/* <button className='plus' name='plus' onClick={(e)=>handleClick(e)}><span style={{position: "relative",bottom: "4px"}}>+</span></button> */}

          {/* <QuantityInput /> */}

        </div>
        <div className='minQty'><strong>*</strong>minimum 50 qty required</div>
      </div> : <div style={{ position: "absolute", right: "0", top: "2rem", marginRight: "18px", display: "flex", justifyContent: "center" }}>coming soon</div>
      }
    </div>
  )
}

export default Card