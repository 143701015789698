import { createSlice } from '@reduxjs/toolkit';
import fetchCartById from './cartRequest';

const initialState = 
  {
    data:[
      {
      id: 0,
      product_image:'test',
      product_name:'test name',
      qty: '111',
      flag: false
      }
    ],
      loading: false,
      isError: false
  };
// const updateUserData = (state, currentData)=>{
//   state.data = {...currentData};
//   return state
// }


const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers:{
        addCart(state,action){
          state.data.push(action.payload)
        },
        updateCart(state,action){
          const{id,qty} = action.payload;
            const parsedState = JSON.parse(JSON.stringify(state.data));
            const index = parsedState.findIndex(st => Number(st.id) === Number(id));
            if(index){
                state.data[index].qty = Number(qty)
            }
        },
        deleteCart(state,action){
          const id = action.payload;
          state.data = state.data.filter(st => Number(st.id) !== Number(id));
        },
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchCartById.pending, (state, action) => {
            state.isError = false
            state.loading = true
          })
          .addCase(fetchCartById.fulfilled, (state, action) => {
            console.log('fetchCartById.fulfilled',action);
            // updateUserData(state,action.payload);
            state.isError = false
            state.loading = false
          })
          .addCase(fetchCartById.rejected, (state, action) => {
            console.log('fetchCartById.rejected',action);
            state.isError = true
            state.loading = false
          })
          .addDefaultCase((state, action) => {
            state.errorMessage = 'something went wrong';
          })
      },
});

export const getAllCart = (state)=>{
  console.log("state",state);
  return state;
}

export const { addCart, updateCart, deleteCart } = cartSlice.actions
export default cartSlice.reducer;
