import { createSlice } from '@reduxjs/toolkit';
import getAllOrders from './orderRequest';



const initialState = {
  data:[
    {
      id: 0,
      product_name:'test',
      product_qty:'1',
      amount: '1$',
      status: "Approved"
    }
  ],
    loading: false,
    isError: false
};
const updateOrderData = (state, currentData)=>{
  state.data = [...currentData];
  return state
}

const orderSlice = createSlice({
    name: 'Order',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          .addCase(getAllOrders.pending, (state, action) => {
            state.isError = false
            state.loading = true
          })
          .addCase(getAllOrders.fulfilled, (state, action) => {
            console.log('getAllOrders.fulfilled',action);
            updateOrderData(state,action.payload);
            state.isError = false
            state.loading = false
          })
          .addCase(getAllOrders.rejected, (state, action) => {
            console.log('getAllOrders.rejected',action);
            state.isError = true
            state.loading = false
            state.errorMessage = action.payload.message
          })
      },
});

export const getOrder = (state)=>{
  return state.order
}

// export const {addUser, updateUser, deleteUser } = userSlice.actions
export default orderSlice.reducer;
