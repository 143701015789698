import React from 'react';
import "./walletCard.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate  } from 'react-router-dom';
import cash from '../../../public/images/dollars.png';
import Chip from '@mui/material/Chip';

function WalletCard(props) {
    return (
        <div>
            <div className='card orderCard'>
                <div className='orderContentDiv'>
                    <div className='orderId'>Order#:{props.order && props?.order?.id}</div>
                    <div className='walletProdName'><span style={{ fontSize: "14px", color: "red" }}>{props.order?.product_name && props?.order?.product_name.charAt(0).toUpperCase() + props?.order?.product_name.slice(1)}</span></div>
                    <div className='orderDate'>{props.order.created_at && new Date(props?.order?.created_at).toUTCString().slice(0, -13)}</div>
                </div>
                <div className='walletPrice'>
                    <div className='walletQty'>Qty : <strong>{props?.order?.product_quantity && props?.order?.product_quantity}</strong></div>
                    <div className='walletRewards'>Points : <strong>{props?.order?.total_reward_point && props?.order?.total_reward_point}</strong></div>
                </div>
                <div style={{marginRight:"10px"}}>
                    {(props?.order?.reward_redeem_mode === "point" && props?.order?.product_status=="requested") && <Chip label="Requested" color="info" /> }
                    {(props?.order?.reward_redeem_mode === "point" && props?.order?.product_status=="approved") && <Chip label="Approved" color="secondary" />}
                    {(props?.order?.reward_redeem_mode === "point" && props?.order?.product_status=="processing") && <Chip label="Processing" color="warning" />}
                    {(props?.order?.reward_redeem_mode === "point" && props?.order?.product_status=="completed") && <Chip label="Completed" color="success" />}
                    {(props?.order?.reward_redeem_mode === "point" && props?.order?.product_status=="rejected") && <Chip label="Rejected" color="error" />}
                    {(props?.order?.reward_redeem_mode === "point" && props?.order?.product_status=="cancelled") && <Chip label="Cancelled" color="error" />}
                    {(props?.order?.reward_redeem_mode === "cash" && props?.order?.product_status !=="cancelled") && <img width={30} height={30} src={cash}/>}
                    {(props?.order?.reward_redeem_mode === "cash" && props?.order?.product_status ==="cancelled") && <Chip label="Cancelled" color="error" />}
                </div>
            </div>
        </div>
    )
}

export default WalletCard