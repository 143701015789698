import React, { useEffect, useState } from 'react';
import OrderCard from '../../components/card/orderCard/OrderCard';
import './orders.css';
import getAllOrders from "../../store/thunk/order/orderRequest";
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Orders(props) {
  const [cookies] = useCookies(['token']);
  const orders = useSelector(state => state.order);
  console.log("orders", orders.data);
  const [allOrders, setAllOrders] = useState(orders.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    console.log(cookies?.token);
    if (typeof cookies?.token !== "undefined" && cookies?.token && cookies?.token?.length > 40) {
      dispatch(getAllOrders({
        token: cookies?.token
      })).then((res) => {
        console.log("orders res", res);
        if (typeof res?.type !== "undefined" && res.type.toString() === "users/order/fulfilled") {
          setShowLoading(false);
        }
      })
    }
  }, [])

  useEffect(() => {
    setAllOrders(orders.data)
  }, [orders])

  useEffect(() => {
    console.log("allOrders", allOrders);
  }, [allOrders])

  // if (typeof cookies.token === "undefined" || !cookies.token || cookies.token.length < 40) {
  //   navigate('/login');
  // } else {
  return (
    <>
      <div className='orderTitle'>ORDER LIST</div>
      <div className='orderCardDiv'>
        {(allOrders.length < 1 || allOrders[0]?.id === 0) ? <div className='noOrderImg'>
          <img width="100%" height="100%" src='https://static.vecteezy.com/system/resources/previews/014/814/311/large_2x/no-order-a-flat-rounded-icon-is-up-for-premium-use-vector.jpg' />;
        </div> : 
        !showLoading ? (allOrders.length > 0 && allOrders[0].id !== 0) && allOrders.map(dt => {
          return <OrderCard order={dt} />
        }) :
          <Box className="homeLoader" sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>}
      </div>
    </>
  )
  // }
}

export default Orders