import React, { useState } from 'react';
import './flex.css';
import flex_img_1 from '../../../../public/images/recycle_img_1.png';
import flex_img_2 from '../../../../public/images/recycle_img_2.png';
import flex_img_3 from '../../../../public/images/recycle_img_3.png';
// import Carousel from 'react-bootstrap/Carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Flex() {

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
      <div className='flexDiv'>
        <Carousel showThumbs={false} width='100%' className='carausal_main'>
                <div>
                    <img className='flexImg' src={flex_img_1} />
                    <p style={{color:'grey',fontSize:'1.1rem',marginBottom: '4rem',fontFamily:'sarif'}}>Recycle and get rewarded <br/> Earn cash while saving the earth</p>
                </div>
                <div>
                    <img src={flex_img_2} />
                    <p style={{color:'grey',fontSize:'1.1rem',marginBottom: '4rem',fontFamily:'sarif'}}>Turn trash into treasure <br/> recycle and reap the rewards</p>
                </div>
                <div>
                    <img src={flex_img_3} />
                    <p style={{color:'grey',fontSize:'1.1rem',marginBottom: '4rem',fontFamily:'sarif'}}>Earn rewards, save the planet<br/>make recycling your habit</p>
                </div>
            </Carousel>
        {/* <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
          <div className='imageDiv'>
            <img 
              src={flex_img_1} 
              className="d-block w-100 h-100 img-fluid"
           />
           </div>
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
          <div className='imageDiv'>
            <img 
              src="https://cdn.pixabay.com/photo/2023/11/04/16/50/ai-generated-8365412_1280.jpg" 
              width="100%"
              height="100%"
            />
            </div>
            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className='imageDiv'>
              <img 
                src="https://img.freepik.com/premium-photo/illustrated-infographic-recycle-bin-full-trash-generative-ai_1645-10244.jpg" 
                width="100%"
                height="100%"
              />
            </div>
            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel> */}
      </div>
      )
}

      export default Flex