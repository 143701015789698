import { createSlice } from '@reduxjs/toolkit';
import getOrderById from './orderDetailRequest';



const initialState = {
  data:{
    name:'test',
    email:'test@xyz.com',
    Phone: '9998887774',
    type: "retail"
  },
    loading: false,
    isError: false
};
const updateOrderDetailData = (state, currentData)=>{
  state.data = [...currentData];
  return state
}

const orderDetailSlice = createSlice({
    name: 'OrderDetail',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          .addCase(getOrderById.pending, (state, action) => {
            state.isError = false
            state.loading = true
          })
          .addCase(getOrderById.fulfilled, (state, action) => {
            console.log('getOrderById.fulfilled',action);
            updateOrderDetailData(state,action.payload);
            state.isError = false
            state.loading = false
          })
          .addCase(getOrderById.rejected, (state, action) => {
            console.log('getOrderById.rejected',action);
            state.isError = true
            state.loading = false
            state.errorMessage = action.payload.message
          })
      },
});

export const getOrder = (state)=>{
  return state.orderDetail
}

// export const {addUser, updateUser, deleteUser } = userSlice.actions
export default orderDetailSlice.reducer;
