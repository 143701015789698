import React,{forwardRef, useState} from 'react';
import "./totalCartContainer.css";
import { useNavigate } from 'react-router-dom';
import deleteImg from "../../public/images/delete.png";
import { useDispatch, useSelector } from 'react-redux';
import { deleteCart } from '../../store/thunk/cart/cartSlice';


function TotalCartContainer(props,ref) {
  const navigate = useNavigate();
  const cart = useSelector(state=>state.cart);
  const dispatch = useDispatch();

  const handleClick = ()=>{
    if(props.totalQty < 50){
      props.setShowQtyError()
    }else{
      navigate('/cart');
    }
  }
  const handleDelete = (e)=>{
    e.preventDefault();
    cart.data.map(dt=>{
      if(dt.id !== 0){
        dispatch(deleteCart(dt.id));
      }
    })
  }
  return (
    <>
        <div ref={ref} className='totalCart'>
            <img className='mainImg' width={60} height={50} src={props?.image}/>
            <div style={{fontWeight:'700'}}>{props.totalItems} ITEMS </div>
            <div onClick={handleDelete}><img width={20} height={20}  src={deleteImg} /></div>
            <button type='button' onClick={handleClick} className='w-50 btn btn-success'>Next</button>
        </div>
    </>
  )
}

export default forwardRef(TotalCartContainer)