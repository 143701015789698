import { createSlice } from '@reduxjs/toolkit';
import getWalletDetails from './walletRequest';



const initialState = {
  data:[
    {
        product_name:'test',
        qty:'1',
        order_id: 0,
        status: "success"
    }
  ],
    loading: false,
    isError: false
};
const updateWalletData = (state, currentData)=>{
  state.data = [...currentData];
  return state
}

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          .addCase(getWalletDetails.pending, (state, action) => {
            state.isError = false
            state.loading = true
          })
          .addCase(getWalletDetails.fulfilled, (state, action) => {
            console.log('getWalletDetails.fulfilled',action);
            updateWalletData(state,action.payload);
            state.isError = false
            state.loading = false
          })
          .addCase(getWalletDetails.rejected, (state, action) => {
            console.log('getWalletDetails.rejected',action);
            state.isError = true
            state.loading = false
            state.errorMessage = action.payload.message
          })
      },
});

export const getwallet = (state)=>{
  return state.wallet
}

export default walletSlice.reducer;
