import React from 'react';
import Signup from '../../pages/signup/Signup';
import "./registerModal.css";

function RegisterModal() {
  return (
    <div className='registerModalDiv'>
        <Signup flag={true}/>
    </div>
  )
}

export default RegisterModal