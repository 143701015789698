import React from 'react';
import "./orderCard.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate  } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function OrderCard(props) {
    console.log("props.order",props.order);
    const navigate = useNavigate();

    const handleClick = ()=>{
        const url = `/order-details/${props.order && props?.order?.id}`;
        console.log("url",url);
        navigate(url);
    }
    
    return (
        <div>
            <div className='card orderCard'>
                <div className='orderContentDiv'>
                    <div className='orderId'>Order#:{props.order && props?.order?.id}</div>
                    <div className='orderDate'>{props.order.created_at && new Date(props?.order?.created_at).toUTCString().slice(0,-7)}</div>
                    <div className='orderStatus'>Reward Redeem: <span style={{fontSize:"14px",color: "var(--bs-cyan)"}}>{props.order?.reward_redeem_mode && props?.order?.reward_redeem_mode.charAt(0).toUpperCase()+props?.order?.reward_redeem_mode.slice(1)}</span></div>
                </div>
                <div className='orderPrice'>
                    <div className='orderFinalAmt'>{props?.order?.order_total_charged_amount ? props?.order?.order_total_charged_amount : 0}$</div>
                </div>
                    <ArrowForwardIosIcon className='orderCardArrow' onClick={handleClick} sx={{color:"green",marginRight:"10px",cursor: 'pointer'}}/>
            </div>
        </div>
    )
}

export default OrderCard