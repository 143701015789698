import React, { useRef } from 'react'
import './bottomNav.css';
import HomeIcon from '@mui/icons-material/Home';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';

function BottomNav() {
    const ref = useRef();
    return (
        <div ref={ref} className='bottomNav align-center'>
            <div className='bottomNav__item'>
                <Link to='/home'>
                    <HomeIcon className='Flex-item' sx={{ fontSize: 25 ,color: 'white' }} />
                    <p>Home</p>
                </Link>
            </div>
            <div className='bottomNav__item'>
                <Link to='/orders'>
                    <LocalShippingIcon className='Flex-item' sx={{ fontSize: 25 , color: 'white' }} />
                    <p>Orders</p>
                </Link>
            </div>
            <div className='bottomNav__item'>
                <Link to="/account">
                    <AccountBalanceIcon className='Flex-item' sx={{ fontSize: 25, color: 'white' }} />
                    <p>Account</p>
                </Link>
            </div>
            <div className='bottomNav__item'>
                <Link to="/wallet">
                    <AccountBalanceWalletIcon className='Flex-item' sx={{ fontSize: 25, color: 'white' }} />
                    <p>Wallet</p>
                </Link>
            </div>
            <div className='bottomNav__item'>
                <Link to="/profile">
                    <AccountCircleIcon className='Flex-item' sx={{ fontSize: 25 , color: 'white' }} />
                    <p>Profile</p>
                </Link>
            </div>
        </div>
    )
}

export default BottomNav