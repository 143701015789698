import { createSlice } from '@reduxjs/toolkit';
import fetchAllProducts from './productRequest';



const initialState = {
    data: [
        {
            "id": 0,
            "product_name": "",
            "product_description": "",
            "product_category_id": 0,
            "product_reward_points": 0,
            "product_cash_conversion_percentage": 0,
            "product_recycling_operation_fee": 0,
            "product_image": ""
        }
    ],
    loading: false,
    isError: false
};
const updateProductList = (state, currentData) => {
    state.data = [ ...currentData?.fetch_products?.products ];
    return state
}

const productSlice = createSlice({
    name: 'Product',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProducts.pending, (state, action) => {
                state.isError = false
                state.loading = true
            })
            .addCase(fetchAllProducts.fulfilled, (state, action) => {
                console.log('fetchAllProducts.fulfilled', action);
                updateProductList(state, action.payload);
                state.isError = false
                state.loading = false
            })
            .addCase(fetchAllProducts.rejected, (state, action) => {
                console.log('fetchAllProducts.rejected', action);
                state.isError = true
                state.loading = false
                state.errorMessage = action.payload.message
            })
    },
});

export const getProducts = (state) => {
    return state.product
}
export default productSlice.reducer;
