import React, { useEffect, useState } from 'react';
import "./pincodeVerification.css";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import fetchAllPincodes from "../../store/thunk/pincode/pincodeRequest";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import LoginModal from '../../components/loginModal/LoginModal';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useGeolocated } from "react-geolocated";

function PincodeVerification() {
    let pincodes = [];
    let allStreets = [];
    let uniqueArray = [];
    let allStreetData = [];
    const pincodesData = useSelector(state => state.pincode);
    const [isLoading, setIsLoading] = useState(true);
    const [allPincodes, setAllPincodes] = useState([]);
    const [streets, setStreets] = useState([]);
    const [streetData, setStreetData] = useState([]);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [selectedPincode, setSelectedPincode] = useState('');
    const [selectedStreet, setSelectedStreet] = useState('');
    const [snackbar, setSnackbar] = useState({ "status": false, "msg": "", Transition: Slide });
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
    const dispatch = useDispatch();
    const [cookies] = useCookies(['token']);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchAllPincodes({
            "get": [
                {
                    "key": "fetch_postal_codes"
                }
            ]
        })).then((res) => {
            if(typeof res?.type !== "undefined" && res.type.toString() === "users/pincode/fulfilled"){
                setIsLoading(false);
              }
        })
    }, [])
    useEffect(() => {
        console.log("coords", coords);
        if(coords){
            localStorage.setItem('location', JSON.stringify(coords));
        }
    }, [coords]);
    useEffect(() => {
        pincodesData.data.map(pc => {
            pincodes = [...pincodes, pc.postal_code];
        })
        const unique = (value, index, array) => {
            return array.indexOf(value) === index
        }
        uniqueArray = pincodes.filter(unique)
        setAllPincodes(uniqueArray);
        console.log("uniqueArray", uniqueArray);
    }, [pincodesData]);

    const handleChange = (event, val) => {
        allStreetData = pincodesData.data.filter(dt => dt.postal_code.toString() === val.toString());
        console.log("allStreetData", allStreetData);
        allStreetData.map(dt => {
            allStreets = [...allStreets, dt.street];
        })
        setStreetData(allStreets);
        setStreets(allStreetData);
        val && setSelectedPincode(val);
        localStorage.setItem('postal_code', JSON.stringify(val));
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if(coords){
            if (selectedPincode === '') {
                setSnackbar({ "status": true, "msg": "Please select a Pincode", "type": "warning" })
            } else if(selectedStreet === ''){
                setSnackbar({ "status": true, "msg": "Please select a Street", "type": "warning" })
            }else if (selectedPincode && allPincodes.indexOf(selectedPincode) === -1) {
                setSnackbar({ "status": true, "msg": "Our Services are not available in this Pincode", "type": "error" })
                setSelectedPincode('');
            } else {
                console.log("loginModal triggered", cookies);
                if (typeof cookies?.token !== "undefined" && cookies.token) {
                    navigate('/checkoutForm');
                } else {
                    setShowLoginModal(true);
                }
            }
        }else{
            setSnackbar({ "status": true, "msg": "Please give location permission to proceed", "type": "warning" })
        }
    }
    const handleClose = () => {
        setSnackbar({ "status": false, "msg": "" })
    }
    const handleStreetChange = (event, value) => {
        if(value){
            const streetId = (streets.length > 0) && streets.find(dt => dt.street.toString() === value.toString()).id;
            console.log("street id", streetId);     // id must be number else rejected
            setSelectedStreet(value);
            localStorage.setItem('street_id', JSON.stringify(streetId));
        }
    }

    return (
        <div className='pincodeMainDiv'>
            {showLoginModal && <LoginModal />}
            <Snackbar
                open={snackbar.status}
                autoHideDuration={3000}
                onClose={handleClose}
                TransitionComponent={snackbar.Transition}
            >
                <Alert
                    severity={snackbar.type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.msg}
                </Alert>
            </Snackbar>
            {!isLoading ? <div className='pincodeDiv'>
                <div className='pincodeTitle'> Please verify your area pincode</div>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allPincodes}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Pincode" />}
                    onInputChange={(event, value) => handleChange(event, value)}
                />
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={streetData}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Street" />}
                    onInputChange={(event, value) => handleStreetChange(event, value)}
                />

                <button type='button' className='w-50 btn btn-info' onClick={handleSubmit}>Verify</button>
            </div> :
                <Box className="homeLoader" sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            }
        </div>
    )
}

export default PincodeVerification