import React from 'react';
import "./aboutus.css";

function AboutUs() {
    return (
        <div className="aboutUsDiv">
            <h3>About Us</h3>
            <h3>Recycle Bee: Redefining Recycling with Convenience and Rewards</h3>
            <p>
                At Recycle Bee, we are dedicated to transforming the way people approach recycling by making it effortless, convenient, and rewarding. Our mission is to create a cleaner and more sustainable future by simplifying the collection of recyclables materials and items.
            </p>
            <h3>Our Story:</h3>
            <p>
                Recycle Bee was founded in 2024, Saint John, New Brunswick, Canada with a clear vision: to tackle the common challenges that make recycling feel like a chore. Confusing sorting rules, inconvenient drop-off locations, and a lack of awareness about recycle Materials programs often discourage people from recycling effectively. Recognizing these pain points, we decided to leverage technology to provide a user-friendly platform that addresses these issues head-on.
            </p>
            <h3>Our Vision:</h3>
            <p>
                We envision a world where recycling is second nature, with every household and business actively contributing to a circular economy. By directing used products to recycling facilities and promoting reuse, we aim to significantly reduce waste, protect the environment, and minimize the exploitation of natural resources.
            </p>
            <h3>Our Mission:</h3>
            <p>
                Our mission is to make recycling accessible and achievable for everyone, from individuals to large institutions. We strive to create an ecosystem of sustainable living by encouraging recycling as a daily habit.
            </p>
            <h3>Core Values:</h3>
            <p>
                <strong>Simplicity:</strong>Our mission is to make recycling accessible and achievable for everyone, from individuals to large institutions. We strive to create an ecosystem of sustainable living by encouraging recycling as a daily habit.
            </p>
            <p>
                <strong>Education:</strong>Empowering users with knowledge is crucial. We provide resources to help individuals understand effective sorting methods and the benefits of reusable bag programs.
            </p>
            <p>
                <strong>Sustainability:</strong>Our reward system incentivizes regular recycling, fostering a sustainable lifestyle and reducing environmental impact.
            </p>
            <p>
                <strong>Innovation:</strong>Continuous technological innovation drives us to improve our services, making recycling more efficient and accessible.
            </p>
            <h3>Our Impact:</h3>
            <p>
                By making recycling effortless and rewarding, Recycle Bee aims to reduce landfill waste and increase recycling rates. Our platform not only benefits individuals but also empowers recycling companies and contributes to a cleaner environment.
            </p>
        </div>
    )
}

export default AboutUs