import React, { useEffect, useMemo, useRef, useState } from 'react';
import "./home.css";
import Card from '../../components/card/Card';
import TotalCartContainer from '../../components/totalcartContainer/TotalCartContainer';
import { useSelector, useDispatch } from 'react-redux';
import fetchAllProducts from "../../store/thunk/product/productRequest";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';


function Home() {
  const homeRef = useRef();
  const [categories, setCategories] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [showTotalCart, setShowTotalCart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const cart = useSelector(state => state.cart);
  const products = useSelector(state => state.product);
  const [allProducts, setAllProducts] = useState([...products.data]);
  const [snackbar, setSnackbar] = useState({ "status": false, "msg": "", Transition: Slide });
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchAllProducts({
      "get": [
        {
          "key": "fetch_products",
          "limit": 20
        }
      ]
    })).then((res) => {
      if (typeof res?.type !== "undefined" && res.type.toString() === "users/products/fulfilled") {
        setIsLoading(false);
      }
    });
  }, []);


  const handleClose = () => {
    setSnackbar({ "status": false, "msg": "" })
}

  useEffect(() => {
    let keys = [];
    
    if (typeof products.data !== "undefined" && products.data.length > 1) {
      products.data.filter(dt => dt.category_type !== 'for_sale').map(data => {
        keys = [...keys, data.category_name];
      });
      const unique = (value, index, array) => {
        return array.indexOf(value) === index
      }
      const uniqueArray = keys.filter(unique)
      setCategories(uniqueArray);
    }
  }, [allProducts]);

  useEffect(() => {
    setAllProducts([...products.data])
  }, [products])


  useEffect(() => {
    if (showTotalCart === true) {
      setTimeout(() => { homeRef.current.className = homeRef.current.className + " totalCartAnimate" }, 100)

    } else {
      if (typeof homeRef.current !== "undefined" && homeRef.current !== null) {
        if (homeRef.current.className.indexOf('totalCartAnimate') !== -1) {
          homeRef.current.className = homeRef.current.className.split(" ").shift()
        }
      }
    }
  }, [showTotalCart])
  const showError = ()=>{
    if(totalQty < 50){
      setSnackbar({ "status": true, "msg": "Minimum order qty must be more than 50", "type": "warning" })
    }
  }
  useEffect(() => {
    let totalCount = 0
    cart.data.map(dt=>{
      if(dt.id !== 0){ 
        totalCount =  totalCount + dt.qty;
      }
    });
    setTotalQty(totalCount)
    if (cart.data.length > 1) {
      setShowTotalCart(true)
    } else {
      setShowTotalCart(false)
    }
  }, [cart])
    
  const handleSelectChange = (e) => {
    const selectedVal = e.target.value;
    if (selectedVal !== "" && categories.indexOf(selectedVal) !== -1) {
      const filteredData = products.data.filter(dt => dt.category_name.toString() === selectedVal.toString());
      setAllProducts(filteredData)
    } else if (selectedVal !== "" && categories.indexOf(selectedVal) === -1 && selectedVal === "all") {
      setAllProducts(products.data)
    }
  }

  return (
    <div className='homeDiv'>
      <Snackbar
        open={snackbar.status}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={snackbar.Transition}
      >
        <Alert
          severity={snackbar.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.msg}
        </Alert>
      </Snackbar>
      <div className='homeTitle'>PRODUCT LIST</div>
      <div className='category'>Category :
        <select onChange={(evt) => handleSelectChange(evt)} className='custom-select w-auto catSelect form-control'>
          <option selected value="all">All</option>
          {categories.length > 0 && categories.map(cat => {
            return <option value={cat}>{cat}</option>
          })}
        </select>
      </div>
      <div className='cardsDiv'>

        {!isLoading ? (allProducts[0].id !== 0) && allProducts.filter(dt => dt.product_type.toString() !== 'for_sale').map((res) => {
          const findRow = cart.data.find(dt => dt.id == res.id);
          if (findRow != undefined) {
            res = { ...res, qty: findRow?.qty }                    // set qty if product is available in cart.
          }
          return (
            <>
              <Card fullData={res} />
            </>
          )
        }) :
          <Box className="homeLoader" sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        }
      </div>
      {showTotalCart && <TotalCartContainer ref={homeRef} image={cart.data[cart.data.length - 1]?.product_image} setShowQtyError={showError} totalQty={totalQty} totalItems={cart.data.length - 1} />}
    </div>
  )
}

export default Home