import React, { useEffect, useState } from 'react';
import "./orderDetails.css";
import dustbin from "../../public/images/dustbin.png";
import Chip from '@mui/material/Chip';
import OrderDetailCard from '../../components/card/orderDetailCard/OrderDetailCard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from "react-cookie";
import getOrderById from '../../store/thunk/orderDetails/orderDetailRequest';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DeleteOrder from '../../store/thunk/order/deleteOrderRequest';

function OrderDetails(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orderDet = useSelector(state => state.orderDetail);
    const [orderDetails, setOrderDetails] = useState(orderDet.data);
    const [cookies] = useCookies(['token']);
    const param = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [currentData, setCurrentData] = React.useState({});

    const handleClick = () => {
        navigate('/orders');
    }
    console.log("orderDet", orderDet, "orderDetails", orderDetails);
    useEffect(() => {
        setOrderDetails(orderDet.data);
    }, [orderDet])

    useEffect(() => {
        if (typeof cookies?.token !== "undefined" && cookies?.token && cookies?.token.length > 40) {
            dispatch(getOrderById({
                token: cookies?.token,
                order_id: param?.order_id
            })).then((res) => {
                setIsLoading(false);
            })
        }
    }, []);
    const openModal = () => {
        setShowModal(true);
    }
    const closeModal = () => {
        setShowModal(false);
    }
     useEffect(() => {
        showModal ?
            document.getElementById("warningModal")?.classList.add("active")
            :document.getElementById("warningModal")?.classList.remove("active");
    }, [showModal]);

    const handleDelete = (e) => {
        e.preventDefault();
        dispatch(DeleteOrder({
            order_id: currentData?.order_id,
            token: cookies?.token,
            products: [
                {
                    id: currentData.product_id,
                    status: "cancelled",
                    qty: currentData.qty
                }
            ]
        })
        ).then(()=>{
            dispatch(getOrderById({
                token: cookies?.token,
                order_id: param?.order_id
            })) 
        })
        setShowModal(false);
    }

    return (
        <div>
            {showModal && <div className="modal" id='warningModal' tabIndex="-1">
                <div className="modal-dialog" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fill-success">Modal title</h5>
                            <button type="button" className="btn-close" onClick={closeModal} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body modal-body">
                            <p>Are you sure you want to cancel this request?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={closeModal} data-bs-dismiss="modal">Close</button>
                            <button type="button" onClick={handleDelete} className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>}
            <div className='d-flex flex-row orderDetailHeader m-4'>
                <ArrowBackIosIcon className='orderCardArrow' onClick={handleClick} sx={{ color: "green", marginRight: "10px" }} />
                <div className='orderDetailId text-end ml-4'>Order#:{orderDetails && orderDetails[0]?.order_id}</div>
            </div>

            {!isLoading ? orderDetails.length > 0 && orderDetails.map((dt => {
                return <OrderDetailCard setCurrentData = {setCurrentData} order_detail={dt} openModal={openModal} />
            })) :
                <Box className="homeLoader" sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            }
        </div>
    )
}

export default OrderDetails