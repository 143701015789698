import { createSlice } from '@reduxjs/toolkit';
import getAccountDetails from './accountRequest';



const initialState = {
  data:[
    {
        product_id:0,
        total_product_reward_points:0,
        total_quality: 0
    }
  ],
    loading: false,
    isError: false
};
const updateAccountData = (state, currentData)=>{
  state.data = [...currentData];
  return state
}

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
          .addCase(getAccountDetails.pending, (state, action) => {
            state.isError = false
            state.loading = true
          })
          .addCase(getAccountDetails.fulfilled, (state, action) => {
            console.log('getAccountDetails.fulfilled',action);
            updateAccountData(state,action.payload);
            state.isError = false
            state.loading = false
          })
          .addCase(getAccountDetails.rejected, (state, action) => {
            console.log('getAccountDetails.rejected',action);
            state.isError = true
            state.loading = false
            state.errorMessage = action.payload.message
          })
      },
});

export const getwallet = (state)=>{
  return state.account
}

export default accountSlice.reducer;
