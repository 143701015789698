import { createSlice } from '@reduxjs/toolkit';
import loginUser from './loginRequest';



const initialState = {
  data:{
    name:'test',
    email:'test@xyz.com',
    Phone: '9998887774',
    type: "retail"
  },
    loading: false,
    isError: false
};
const updateUserData = (state, currentData)=>{
  state.data = {...currentData};
  return state
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers:{
      deleteToken(state,action){
        const id = action.payload;
        state.data.token = "";
        state.data.user = "";
      },
    },
    extraReducers: (builder) => {
        builder
          .addCase(loginUser.pending, (state, action) => {
            state.isError = false
            state.loading = true
          })
          .addCase(loginUser.fulfilled, (state, action) => {
            console.log('loginUser.fulfilled',action);
            updateUserData(state,action.payload);
            state.isError = false
            state.loading = false
          })
          .addCase(loginUser.rejected, (state, action) => {
            console.log('loginUser.rejected',action);
            state.isError = true
            state.loading = false
            state.errorMessage = action.payload.message
          })
      },
});

export const getUser = (state)=>{
  return state.login
}
export const { deleteToken } = loginSlice.actions
export default loginSlice.reducer;
