import { createSlice } from '@reduxjs/toolkit';



const initialState = {
  data:{
    status:false,
    message:'test',
    type: "error"
  }
};
const updateErrorMsg = (state, currentData)=>{
    console.log("current Data", currentData);
  state.data = {...currentData};
  return state
}

const errorMessageSlice = createSlice({
    name: 'error',
    initialState,
    reducers:{
      setMessage(state,action){
        updateErrorMsg(state, action.payload)
      },
    }
});
export const { setMessage } = errorMessageSlice.actions
export default errorMessageSlice.reducer;
