import React from 'react';
import { useSelector } from 'react-redux';
import './mainPage.css';
import Header from './header/Header';
import Footer from './footer/Footer';
import Body from './body/Body';
import fetchUserById from '../../store/thunk/login/loginRequest';
import BottomNav from '../bottomNav/BottomNav';

function MainPage() {

  return (
    <>
      <div>
        <Header />
        <Body />
        <Footer />
        <BottomNav />
      </div>
    </>
  )
}

export default MainPage