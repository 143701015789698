import React, { useEffect,useState } from 'react'
import "./account.css";
import plastic from "../../public/images/plastic.png"
import bottles from "../../public/images/wine-bottles.png"
import can from "../../public/images/can.png"
import reward from "../../public/images/reward.png"
import getAccountDetails from '../../store/thunk/account/accountRequest';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import cash from "../../public/images/dollars.png";
import { setMessage } from '../../store/thunk/error/errorMessage';
import { deleteToken } from '../../store/thunk/login/loginSlice';

function Account() {
  const [showLoading, setShowLoading] = useState(true);
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector(store => store.account);
  console.log("account",account);
  const [accountData, setAccountData] = useState(account);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCash, setTotalCash] = useState(0);

  useEffect(() => {
    const splitUrl = window.location.href.split('/');
    const lastWord = splitUrl[splitUrl.length - 1];
    if (lastWord.toString() === "account") {
      console.log("cookies?.token",cookies?.token);
      if (typeof cookies?.token === "undefined" || cookies?.token === "") {
        console.log("login triggered");
        navigate('/login');
      }
    }
    dispatch(getAccountDetails({
      token: cookies?.token
    })).then(res => {
      if (typeof res?.type !== "undefined" && res.type.toString() === "users/account/fulfilled") {
        setShowLoading(false);
      }else if(res?.payload?.response?.status === 401){
        console.log("account login triggered");
        dispatch(deleteToken());
        dispatch(setMessage({
          status:true,
          message:"Login Expired",
          type:"error"
        }));
          navigate('/login');
      }
    })
  }, []);
  useEffect(()=>{
    setAccountData(account);
    let total = 0
    // const pointData = account?.data?.filter(dt=>dt.reward_redeem_mode === "point");
    // const cashData = account?.data?.filter(dt=>dt.reward_redeem_mode === "cash");
    account?.data?.length > 0 && account.data.map(dt=>{
      total = total + Number(dt.total_product_reward_points)
    })
    setTotalAmount(total);
  },[account])
  useEffect(()=>{
    console.log("accountData",accountData);
  },[accountData])
  console.log("accountData?.data.find(dt=>dt.product_id === 3)?.total_quality",accountData?.data.find(dt=>dt.product_id === 3) !== undefined);
  const summeryRow = (color, name, image, qty) => {
    return (
      <div className='accountBody'>
        <img className='summeryImage' style={{ backgroundColor: color }} width={45} height={45} src={image} />
        <div className='summeryContent'>
          <div style={{ fontWeight: 700, color: "#636a70", fontSize: "large" }}>{qty}</div>
          <div>Total {name}</div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='accountTitle'>SUMMERY</div>
      {!showLoading && summeryRow('lightgreen', 'reward points', reward, totalAmount)}
      {!showLoading && summeryRow('green', 'cash', cash, totalCash)}
      {!showLoading && summeryRow('lightblue', 'plastic bottles', plastic,(accountData?.data.length > 0 && accountData?.data[0]?.product_id > 0 && accountData?.data.find(dt=>dt.product_id === 1) !== undefined ) ? accountData?.data.find(dt=>dt.product_id === 1)?.total_quality : 0)}
      {!showLoading && summeryRow('yellow', 'cans', can,(accountData?.data.length > 0 && accountData?.data[0]?.product_id > 0 && accountData?.data.find(dt=>dt.product_id === 2) !== undefined ) ? accountData?.data.find(dt=>dt.product_id === 2)?.total_quality : 0)}
      {!showLoading && summeryRow('orange', 'glass bottles', bottles,(accountData?.data.length > 0 && accountData?.data[0]?.product_id > 0 && accountData?.data.find(dt=>dt.product_id === 3) !== undefined ) ? accountData?.data.find(dt=>dt.product_id === 3)?.total_quality : 0)}
      {showLoading && 
      <Box className="homeLoader" sx={{ display: 'flex',position:"fixed", padding:"2rem", backgroundColor:"#fff", top:"22rem", zIndex:"100", height:"10% !important",left:"40%" }}>
        <CircularProgress />
      </Box>}
    </div>
  )
}

export default Account