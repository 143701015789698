import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import data from "../../../config.json";

const orderCheckout = createAsyncThunk(
    'users/checkout',
    async (postData, thunkAPI) => {
        console.log('[order checkout] postData', postData!== undefined ? postData : '');
        try {
            const url = data?.api?.cart_post;
            const token = typeof postData?.token !== "undefined" && postData?.token
            const config = {
                headers: { 
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin":  'https://recyclebee.ca/api/user/checkout',
                    "Access-Control-Allow-Headers": "Content-Type, Authorization",
                }
            };
            console.log(token,url,config);
            if(url){
                const response = await axios.post(url, postData, config);
                console.log('[order checkout] response',response );
                if(response.status === 200 && response.data.status === true){
                    return thunkAPI.fulfillWithValue(response.data);
                }else{
                    throw thunkAPI.rejectWithValue(response.data.message);
                }
            }else{
                throw thunkAPI.rejectWithValue('No API URL found');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
  )
  export default orderCheckout;